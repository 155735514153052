<script setup lang="ts">
import { ref, watch } from 'vue';
import { getNewLeadsService } from "@/api/index";
import { useMessage } from 'naive-ui';
import VueTurnstile from 'vue-turnstile';
import FullscreenModal from '~/components/FullscreenModal.vue';
import HomeVideo from '~/components/HomeVideo.vue';
import { Close } from '@vicons/ionicons5';
const message = useMessage();
const homeBanner = ref();
const isBottom = ref(false);
const isPlaying1 = ref(true), isPlaying2 = ref(false);
const videoRef1 = ref<any>(null), videoRef2 = ref<any>(null);
const openModal = ref(false);
const openMenu = ref(false);
const cloud_key = process.env.CLOUD_KEY || "";
const token = ref<string>('');
const loading = ref(false);
const formRef = ref<any>(null);
const modalVisible = ref(false);
const imgUrl = (url: string) => {
    return config.app.cdnURL + url;
}
const modelForm = ref<any>({
    full_name: '',
    email: '',
    title: '',
    company: '',
    request: ''
})
const modelRules = ref<any>({
    full_name: [{
        required: true,
        message: 'please enter your full name',
        trigger: 'blur'
    }],
    email: [{
        required: true,
        message: ' please enter your email',
        trigger: 'blur'
    },
    {
        type: 'email',
        message: 'please enter a valid email',
        trigger: 'blur'
    }],
    title: [{
        required: true,
        message: 'please enter your title',
        trigger: 'blur'
    }],
    company: [{
        required: true,
        message: 'please enter your company',
        trigger: 'blur'
    }],
})
const config = useRuntimeConfig();
const videoPlayer = (videoRef: any, palyStatus: any) => {
    videoRef.play();
    if (palyStatus === 'isPlaying1') {
        isPlaying1.value = true;
    } else {
        isPlaying2.value = true;
    }
}
const scrollBanner = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target
    if (scrollTop + clientHeight === scrollHeight) {
        console.log('滚动到底部啦')
        isBottom.value = true;
    } else {
        isBottom.value = false;
    }
}

const isModal = ref(false);
const handleCHange = (val: any) => {
    isModal.value = val;
}
const close = () => {
    openModal.value = false;
}
const handleValidateButtonClick = () => {

    formRef.value?.validate((errors: any) => {
        if (!errors) {
            loading.value = true;
            getNewLeadsService(modelForm.value).then((res: any) => {
                if (res) {
                    message.success('Request submitted successfully');
                    loading.value = false;
                    openModal.value = false;
                }
            }).catch(err => {
                console.log(err)
                loading.value = false;
                message.error('Request failed, please try again later');
            })
        }
    })
}

const handleClose = () => {
    modalVisible.value = false;
}

const openModals = () => {
    modalVisible.value = true;
}

watch(() => token.value, (val) => {
    if (val && val.length > 0) {
        localStorage.setItem('CToken', val)
    }
}, { immediate: true })


const isMobile = ref(false);

const checkIsMobile = () => {
    isMobile.value = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

onMounted(() => {
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkIsMobile);
});
</script>

<template>
    <div :class="['home_wrap', isBottom ? 'mobileHome_wrap' : '', isModal ? 'mobileModal' : '']" ref="homeBanner"
        @scroll="scrollBanner">
        <headItem @change="handleCHange" />
        <div class="animate_bg_wrap">
            <div class="animate_bg_box">
                <div class="animate01 animate_box animate_small">
                </div>
                <div class="animate02 animate_box animate_middle">
                </div>
                <div class="animate03 animate_box animate_large">
                </div>
                <div class="animate04 animate_box animate_middle">
                </div>
                <div class="animate05 animate_box animate_large">
                </div>
            </div>
        </div>
        <div class="banner_box" ref="bannerBox">
            <div class="container">
                <div class="banner_container">
                    <h1 class="banner_top">
                        <!--<p class="new_slogan">A Mayo Clinic Platform_Accelerate Company</p>-->
                        <span class="slogan">
                            <!-- Your <i class="shade">AI Copilot</i> for -->
                            <i>AI-powered</i> <i>clinical development</i>
                        </span>
                        <p>
                            <!-- Clinical Trial Design -->
                            <span>
                                Bringing data-driven insights and statistical innovations
                            </span>
                            <span>to clinical teams' fingertips</span>
                        </p>
                    </h1>


                    <!-- <div class="speak_team" @click.prevent="openModal = true">
                        Speak with our team
                    </div> -->

                </div>

            </div>
        </div>
        <div class="unlock_wrap">
            <div class="container">
                <div class="clinician_box">
                    <h1 class="title">
                        Our specialized AI scientists help clinical development teams design faster, more precise trials
                    </h1>
                    <div class="img_box video_box" @click="openModals">

                        <template v-if="isMobile">
                            <HomeVideo videoSrc="https://demo.hopeai.co/AI_Scientists.mp4"
                                :posterUrl="imgUrl('/img/group.png')" />
                        </template>
                        <template v-else>
                            <img :src="imgUrl('/img/group.png')" alt="">
                            <div class="pb_player_box">
                                <img class="players" :src="imgUrl('/img/player.png')" alt="">
                            </div>
                            <FullscreenModal v-model:visible="modalVisible"
                                videoSrc="https://demo.hopeai.co/AI_Scientists.mp4" :autoplay="true" :controls="true"
                                @close="handleClose" />
                        </template>

                    </div>
                </div>
                <div class="work_item_box ">
                    <div class="work_item  ">
                        <div class="work_item_left product_partner">
                            <h2>
                                Our AI Clinician delivers unmatched clinical evidence accuracy
                            </h2>
                            <p>According to a large scale (4,500 data points) blinded evaluation by physicians from:</p>
                            <div class="partner_img_box">
                                <img class="mk_img " :src="imgUrl('/img/fred_hutch.png')"
                                    alt="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu"
                                    title="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu" />
                                <img class="mk_img " :src="imgUrl('/img/hemonc_write.png')"
                                    alt="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu"
                                    title="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu" />
                            </div>
                            <!-- <p class="btn">
                                <a :href="imgUrl('/pdf/Leveraging AI for Modeling MRD and Survival Outcomes in Multiple Myeloma.pdf')"
                                    target="_blank">
                                    Read the paper
                                </a>
                            </p> -->
                        </div>
                        <div class="work_item_right ">
                            <img class="mk_img " :src="imgUrl('/img/index_02.png')"
                                alt="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu"
                                title="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu" />
                        </div>
                    </div>
                    <div class="work_item     fadeInDown">
                        <div class="work_item_left statistician">
                            <h2>
                                Our AI Statistician leverages decades of innovations in trial design
                            </h2>
                        </div>
                        <div class="work_item_right  ">
                            <img class="mk_img" :src="imgUrl('/img/index_03.png')"
                                alt="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu"
                                title="HopeAI, Hope AI, clinical trials, AI, Copilot, adaptive design, Will Ma, Feifang Hu" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="solution_box">
            <div class="solution_product1 solution_product">
                <div class="container">
                    <div class="flex">
                        <div class="flex_left">
                            <h1 class="solution_title">
                                PURE <span class="solution_shade">Evidence</span>
                            </h1>
                        </div>
                        <div class="flex_right solution01_img">
                            <ul>
                                <li>
                                    <strong>Precise</strong> - Covering both study-level and subgroup-level clinical
                                    outcome
                                </li>
                                <li>
                                    <strong>Up-to-date</strong> - Covering both primary and follow-up publications
                                </li>
                                <li>
                                    <strong>Reliable</strong> - Curated by clinical scientists, standardized by
                                    statisticians
                                </li>
                                <li>
                                    <strong>Easily accessible</strong> - Readily available through natural language
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="solution_product2 solution_product">
                <div class="container">
                    <div class="flex">
                        <div class="flex_right solution02_img">
                            <ul>
                                <li>
                                    <strong>Synthetic individual patient data</strong> generated by AI based on real
                                    clinical trial results
                                </li>
                                <li>
                                    Provides a practical and cost-effective way to access individual-level clinical
                                    trial data while safeguarding privacy
                                </li>
                                <li>
                                    Enable the design of smaller and more targeted trials and shorten the drug
                                    development timeline
                                </li>
                            </ul>
                        </div>
                        <div class="flex_left">
                            <h1 class="solution_title">
                                Synth<span class="solution_shade">IPD</span>
                            </h1>

                        </div>

                    </div>
                </div>
            </div>
            <div class="solution_product1 solution_product">
                <div class="container">
                    <div class="flex">
                        <div class="flex_left">
                            <h1 class="solution_title">
                                CARA <span class="solution_shade">Design</span>
                            </h1>
                        </div>
                        <div class="flex_right solution01_img">
                            <ul>
                                <li>
                                    <strong>Covariate-adjusted response adaptive</strong> randomization for
                                    precision
                                    medicine
                                </li>
                                <li>
                                    Increase the chance of patients being randomized to superior treatments
                                </li>
                                <li>
                                    Make the trial more competitive, attractive and accelerate patient recruitment
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="product_box">
            <div class="container">
                <div class="flex">
                    <div class="pro_left">
                        <h1 class="pro_title">
                            Clinical teams use <span class="shade">
                                PURE Evidence
                            </span> and <span class="shade">SynthIPD</span> to establish surrogate endpoints
                        </h1>
                        <p class="pro_content">
                            In collaboration with <i>Mayo Clinic</i>,
                            <i>Fred Hutch Cancer Center</i> and <i>Takeda</i>
                        </p>
                    </div>
                    <div class="pro_right">
                        <p>
                            The association between MRD negativity and survival endpoints in multiple myeloma was
                            validated using AI-powered clinical evidence. A prior project led by our collaborators
                            resulted in the FDA's approval of MRD as an endpoint for accelerated approval. Now, with
                            our
                            PURE Evidence and SynthIPD, we can deliver more comprehensive and up-to-date association
                            analyses at both the trial and patient levels in weeks, rather than years.
                        </p>
                        <p class="btn">
                            <a :href="imgUrl('/pdf/Leveraging AI for Modeling MRD and Survival Outcomes in Multiple Myeloma.pdf')"
                                target="_blank">
                                Read the paper
                            </a>

                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="product_box last_product">
            <div class="container">
                <div class="flex">
                    <div class="pro_left">
                        <h1 class="pro_title">
                            Oncologists use <span class="shade">HopeAI</span> for evidence-based medicine
                        </h1>
                        <p class="pro_content">
                            In partnership with <i>HemOnc.org</i>, the world's largest medical wiki of treatment
                            information
                            for hematology and oncology
                        </p>
                    </div>
                    <div class="pro_right">
                        <p>
                            Utilizing the best aspects of human expertise and AI to address the unmet need for
                            reliable
                            clinical evidence, our AI Copilot enables oncologists to gain deep insights into
                            treatment
                            regimens and their supporting clinical trials by simply asking questions. An AI-driven
                            workflow, combined with expert curation, ensures the accuracy and relevance of the
                            answers.
                        </p>
                        <p class="btn">
                            <a href="https://hemonc.org/wiki/Multiple_myeloma,_relapsed-refractory" target="_blank">
                                Try AI Copilot
                            </a>

                        </p>
                    </div>
                </div>
            </div>
        </div>
        <footItem />
        <n-modal v-model:show="openModal" :auto-focus="false" :class="[openMenu ? 'mobile_modal' : '']">
            <n-card style="width: 600px" title="Request a Demo" :bordered="false" size="huge" role="dialog"
                :aria-modal="true">
                <template #header-extra>
                    <n-icon size="20" @click.prevent="close" style="cursor: pointer;">
                        <Close />
                    </n-icon>
                </template>

                <div class="request_modal">
                    <p class="request_notice">Enter your contact details and we'll be in touch</p>
                    <n-form ref="formRef" :model="modelForm" :rules="modelRules">
                        <n-form-item path="full_name" label="Full Name">
                            <n-input v-model:value="modelForm.full_name" placeholder="" />
                        </n-form-item>
                        <n-form-item path="email" label="Email">
                            <n-input v-model:value="modelForm.email" placeholder="" @keydown.enter.prevent />
                        </n-form-item>
                        <n-form-item path="title" label="Title">
                            <n-input v-model:value="modelForm.title" placeholder="" @keydown.enter.prevent />
                        </n-form-item>
                        <n-form-item path="company" label="Company">
                            <n-input v-model:value="modelForm.company" placeholder="" @keydown.enter.prevent />
                        </n-form-item>
                        <n-form-item path="request" label="">
                            <div class="request_form_item">
                                <p>What are you most interested in learning about? </p>
                                <p>
                                    How can we help? Share more here.</p>
                                <n-input v-model:value="modelForm.request" type="textarea" :autosize="{
                                    minRows: 3,
                                    maxRows: 3,
                                }" placeholder="" @keydown.enter.prevent />
                            </div>
                        </n-form-item>
                        <n-row :gutter="[0, 24]">
                            <n-col :span="24">
                                <div style="display: flex; justify-content: center">
                                    <template v-if="openModal">
                                        <vue-turnstile :site-key="cloud_key" v-model="token" theme="auto" />
                                    </template>
                                    <n-button class="request_submit" :disabled="token.length === 0" round type="primary"
                                        size="large" :loading="loading" @click="handleValidateButtonClick">
                                        Submit
                                    </n-button>
                                </div>
                            </n-col>
                        </n-row>
                    </n-form>
                </div>

            </n-card>
        </n-modal>


    </div>
</template>

<style lang="scss">
.pb_player_box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(13, 18, 31, 0.15);
    border-radius: 32px;
    transition: background 0.3s ease;

    .players {
        width: 100px !important;
        height: 100px;
        transition: transform 0.3s ease;
    }

    &:hover {
        background: rgba(13, 18, 31, 0.25);


    }
}

.video_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.video-player {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

}

.hide {
    // display: none;
}

.ant-modal-mask {
    background: rgba(13, 18, 31, 0.85);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
}

.ant-modal-content {
    border-radius: 8px !important;
}

.ant-modal-body {
    padding: 48px !important;
}

@media screen and (max-width: 1024px) {
    .ant-modal-centered .ant-modal {
        width: 70% !important;
    }

    .video-player {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    .ant-modal-centered .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
        width: 100% !important;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh);
    }

    .ant-modal-body {
        flex: 1;
        padding: 32px !important;
        height: 100%;
        overflow: hidden;
    }

    .ant-modal-content {
        border-radius: 0 !important;
    }

    .profile_modal {
        padding-bottom: 60px;
    }
}

.bgWrap {
    position: relative;
}

.bgImg {
    position: absolute;
    top: 234px;
    width: 80%;
    right: -20px;
}

.dialogcon {
    position: relative;
    width: 100%;
    height: 100%;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    background: url(/img/close.svg) no-repeat center;
    background-size: 100%;
    z-index: 111;
    // cursor: pointer;
}

.dialog {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 100;
}

.video_box_01 {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.video_box_right {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
}


.lb_ban {
    display: block;
    position: absolute;
    bottom: -88px;
    left: -70px;
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    background: url(/img/Ellipse_lb.png) no-repeat center;
    filter: blur(50px);
}

.animate_bg_wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    overflow: hidden;
    z-index: -10;
    display: flex;
    align-items: center;
    justify-self: center;

    .animate_bg_box {
        position: absolute;
        width: 100%;
        z-index: -10;
        overflow: hidden;
    }

    .animate_box {
        filter: blur(64px);
    }

    .animate_small {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        filter: blur(34px);
    }

    .animate_middle {
        width: 500px;
        height: 500px;
        border-radius: 50%;
        filter: blur(50px);
    }

    .animate_large {
        width: 600px;
        height: 600px;
        border-radius: 50%;
        filter: blur(74px);
    }

    .animate01 {
        background: #DC2DEE;
        opacity: 0.25;
        animation: 18s ease-in-out 0s infinite normal none running animateBlob2;
        transform: translate(80.8102vw, 7.86251vh);
    }

    .animate02 {
        background: #512685;
        opacity: 0.5;
        animation: 15s ease-in-out 0s infinite normal none running animateBlob1;
        transform: translate(81.4858vw, 83.6355vh);
    }

    .animate03 {
        background: #7A47B9;
        opacity: 0.5;
        animation: 18s ease-in-out 0s infinite normal none running animateBlob3;
        transform: translate(27.0167vw, 64.8606vh);
    }

    .animate04 {
        background: #BE35B0;
        opacity: 0.35;
        animation: 21s ease-in-out 0s infinite normal none running animateBlob4;
        transform: translate(20.5473vw, 35.7899vh);
    }

    .animate05 {
        background: #B588EC;
        opacity: 0.4;
        animation: 24s ease-in-out 0s infinite normal none running animateBlob5;
        transform: translate(66.0569vw, 50.9782vh);
    }
}

.play-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 16px;
}

.vide-play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    // cursor: pointer;
}







.banner_box {
    width: 100%;
    height: 100vh;
    // cursor: pointer;

    .speak_team {
        color: #fff;
        border-radius: 99px;
        border: 1px solid var(--brand-purple-5, #6836A8);
        background: var(--YI, #6939A3);
        display: inline-block;
        padding: 12px 14px;
        font-size: 16px;
        font-weight: 500;
        margin-top: 90px;
        z-index: 2;
        padding: .7rem 1.5rem;
        font-weight: 500;
        position: relative;
        z-index: 100;
        cursor: pointer;

        &:hover {
            background: #7A47B9FF;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 90px;
        // cursor: pointer;
    }

    .banner_container {
        // max-width: 910px;
        margin: 0;
        width: 96%;
        // cursor: pointer;
    }



}



.banner_box .banner_img {
    width: auto;
    height: auto;
    display: block;
    margin: 0px auto 40px auto;
}



.partner_box {
    padding: 0 0 120px 0;

    .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.partner_banner_title {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 150%;
    z-index: 10;
    margin-bottom: 20px;
}

.partner_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    gap: 54px;

    img {
        height: 136px;
        width: auto;
    }
}

.unlock_wrap {
    background: linear-gradient(133deg, #673E9A 6.2%, #52327B 89.06%);
    padding: 120px 0;


    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .clinician_box {
        display: flex;
        flex-direction: column;
        gap: 60px;

        .title {
            font-size: 36px;
            text-align: center;
            width: calc(100% - 300px);
            margin: 0 auto;
            font-weight: 600;
            color: #fff;
            line-height: 44px;

        }

        .img_box {
            img {
                width: 100%;
                border-radius: 32px;
            }
        }
    }

    .work_item_box {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 60px;

        .work_item {
            display: flex;
            gap: 60px;
            padding: 36px 40px;
            border-radius: 32px;
            border: 1px solid rgba(0, 0, 0, 0.04);
            background: rgba(0, 0, 0, 0.2);

            .work_item_left,
            .work_item_right {
                flex: 1;
            }

            .work_item_right {
                img {
                    width: 100%;
                    border-radius: 8px;
                }
            }
        }
    }

    .product_partner {
        display: flex;
        flex-direction: column;
        gap: 30px;
        color: #fff;

        h2 {
            font-size: 36px;
            font-weight: 600;
            line-height: 44px;
        }

        p {
            font-size: 24px;
            line-height: 38px;
        }

        .partner_img_box {
            display: flex;
            gap: 30px;

            img {
                height: 64px;
            }
        }

        .btn {
            position: relative;
            z-index: 100;
            cursor: pointer;
            border-radius: 99px;
            border: 1px solid #6836A8;
            background: #6939A3;
            font-size: 16px;
            font-weight: 600;
            width: 168px;

            a {
                color: #fff;
                font-weight: 600;
                display: block;
                width: 100%;
                height: 100%;
                padding: 7px 24px;
            }
        }
    }

    .statistician {
        display: flex;
        align-items: center;

        h2 {
            font-size: 36px;
            color: #fff;
            line-height: 56px;
            font-weight: 600;
        }
    }




}

.solution_box {
    background: #F8F0FF;
    padding: 60px 0;
}

.solution_product1,
.solution_product2 {
    // padding: 120px;

    .flex {
        padding: 60px 100px;
        gap: 100px;
        border-bottom: 1px solid #999;
        align-items: center;
        // &:last-child {
        //     border-bottom: none;
        // }
    }

    .solution_title {
        font-size: 72px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    ul {

        li {
            font-size: 24px;
            font-style: normal;
            line-height: 32px;
            margin-bottom: 8px;
        }
    }
}

.solution_product {
    &:last-child {
        .flex {
            border-bottom: none;
        }
    }
}

.solution_shade {
    background: linear-gradient(98deg, #802DF5 22.91%, #DC2DEE 58.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 72px;
    font-weight: 700;
    font-style: normal;
    text-shadow: none;
    line-height: normal;
}

.last_product {
    .container {
        border-bottom: none !important;
    }
}

.product_box {
    .container {
        padding: 120px 0;
        border-bottom: 1px solid rgba(193, 193, 202, 0.40);

    }

    .flex {
        gap: 48px;
    }

    .product_title {
        color: #FFF;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px;
    }

    .shade {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px;
    }

    .pro_title {
        color: #FFF;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px;
    }

    .pro_content {
        color: rgba(255, 255, 255, 0.80);
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;

        i {
            font-style: normal;
            color: #fff;
            font-weight: 500;
        }
    }

    .pro_left {
        flex: 4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;


    }

    .pro_right {
        flex: 6;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;

        p {
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            &.btn {
                position: relative;
                z-index: 100;
                cursor: pointer;
                border-radius: 99px;
                border: 1px solid #6836A8;
                background: #6939A3;
                font-size: 16px;
                font-weight: 600;

                a {
                    color: #fff;
                    font-weight: 600;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 7px 24px;
                }

                &:hover {
                    background: #7A47B9FF;
                }
            }
        }
    }
}

.banner_box {
    .banner_top {
        position: relative;
        // width: 527px;
        margin: 0 auto;
        color: #FFF;
        text-align: left;
        line-height: 150%;
        z-index: 10;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25);
        // margin-top: 30px;

        .new_slogan {
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 38px;
        }

        span {
            display: block;
            position: relative;
            z-index: 10;
            color: #FFF;
            text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25);
            font-size: 36px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        .slogan {
            font-size: 72px;
            font-weight: 700;
            margin-bottom: 32px;

            i {
                font-style: normal;
                font-weight: 700;
            }

        }

        .shade {
            font-weight: 800;
            font-size: 72px;
        }


    }
}


.shade {
    background: linear-gradient(98deg, #802DF5 22.91%, #DC2DEE 58.87%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    text-shadow: none;
    line-height: 110%;
}

.nav_product1,
.nav_product2 {
    padding: 80px 0;

    .flex {
        gap: 60px;
    }
}

.nav_product4 {
    padding: 80px 0 120px 0;
}

.product_title {
    font-family:
        -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 62px;
    color: #FFFFFF;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    position: relative;
    z-index: 10;
}

.flex {
    display: flex;
}

.flex_left {
    flex: 4;
}

.flex_right {
    flex: 6;
    display: flex;
}

.product01_img {
    justify-content: end;
    align-items: baseline;
}

.product02_img {
    justify-content: start;
    align-items: baseline;
}


.product_content {
    margin-top: 32px;
    font-family:
        -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
        Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #C1C1CA;
    flex: none;
    order: 1;
    flex-grow: 0;
    // display: none;
}

.product_img01 {
    width: 100%;
    display: block;
    border-radius: 16px;
}

.product_img02 {
    width: 100%;
    display: block;
    border-radius: 16px;
}



@keyframes animateBlob1 {
    0% {
        transform: translate(11.82079309187229vw, 24.96595982386701vh);
    }

    25% {
        transform: translate(24.54175811702291vw, 35.70676736748425vh);
    }

    50% {
        transform: translate(38.161019089972356vw, 81.32325729877667vh);
    }

    75% {
        transform: translate(89.61830534629931vw, 7.836224200551767vh);
    }

    100% {
        transform: translate(81.82079309187229vw, 64.96595982386701vh);
    }
}

@keyframes animateBlob2 {
    0% {
        transform: translate(69.7130711321029vw, 37.29391065427885vh);
    }

    25% {
        transform: translate(10.233413765149656vw, 101.13517608566744vh);
    }

    50% {
        transform: translate(27.408715586013233vw, 63.66088453729726vh);
    }

    75% {
        transform: translate(32.69164224091578vw, 91.68110024118046vh);
    }

    100% {
        transform: translate(69.7130711321029vw, 37.29391065427885vh);
    }
}

@keyframes animateBlob3 {
    0% {
        transform: translate(64.83222381153115vw, 56.9305523739702vh);
    }

    25% {
        transform: translate(45.59169118011894vw, 55.114131053974404vh);
    }

    50% {
        transform: translate(36.23736674882843vw, 96.22568076101967vh);
    }

    75% {
        transform: translate(71.78715604036958vw, 32.70317400608938vh);
    }

    100% {
        transform: translate(64.83222381153115vw, 56.9305523739702vh);
    }
}

@keyframes animateBlob4 {
    0% {
        transform: translate(52.3226425772783vw, 80.39994368317865vh);
    }

    25% {
        transform: translate(82.82976877739536vw, 16.9646167524403vh);
    }

    50% {
        transform: translate(83.49595269413092vw, 86.536671377477vh);
    }

    75% {
        transform: translate(90.04273334087989vw, 82.64523635602355vh);
    }

    100% {
        transform: translate(52.3226425772783vw, 80.39994368317865vh);
    }
}

@keyframes animateBlob5 {
    0% {
        transform: translate(42.240409236308096vw, 86.07545364265717vh);
    }

    25% {
        transform: translate(92.57433845288628vw, 88.86549267210148vh);
    }

    50% {
        transform: translate(3.046095758175893vw, 90.33367652224335vh);
    }

    75% {
        transform: translate(11.213993850126913vw, 67.10271016858415vh);
    }

    100% {
        transform: translate(42.240409236308096vw, 86.07545364265717vh);
    }
}







@media (max-width: 576px) {
    .partner_banner_title {
        font-size: 20px;
    }

    .video_box {
        .video-player {
            border-radius: 16px;
        }
    }

    .pb_player_box {
        border-radius: 16px;
    }

    .video_box img {
        border-radius: 16px !important;
    }

    .partner_box {
        margin-top: 60px;
    }

    .partner_wrap {
        max-width: 100%;
        gap: 20px;

        img {
            height: 68px;
        }
    }

    .banner_box {
        .container {
            padding-top: 0;
        }

        .banner_title {
            font-size: 20px;
            margin: 81px auto 20px auto;
        }

        .banner_container {
            max-width: 100%;
            margin: 0 auto;
        }

        .banner_top {
            font-size: 40px;

            .new_slogan {
                font-size: 22px;
                line-height: 30px;
                width: calc(100% - 46px);
            }

            .slogan {
                font-size: 34px;
                margin-bottom: 24px;
                display: block;

                i {
                    display: block;
                }
            }

            span {
                font-size: 20px;
                display: inline;
            }

            .shade {
                font-size: 40px;
            }

            p {
                line-height: 14px;
            }
        }

        .partner_wrap {
            width: 100%;
            justify-content: flex-start;

            img {
                height: 75px;
            }

        }
    }


    .lb_ban {
        width: 200px;
        height: 200px;
        left: -30px;
        bottom: -72px;
    }

    .nav_product1 {
        padding: 60px 0;

        .flex {
            flex-flow: column-reverse;
        }
    }

    .product_title {
        font-size: 32px;
        line-height: 36px;

        .shade {
            font-size: 32px;
            line-height: 36px;
        }
    }

    .product01_img,
    .product02_img {
        margin-bottom: 26px;
    }

    .product_img01,
    .product_img02 {
        width: 100%;
    }

    .nav_product2 {
        padding: 60px 0;

        .flex {
            flex-flow: column;
        }
    }

    .nav_box {
        padding-bottom: 80px;
    }


    .unlock_wrap {
        padding: 60px 0;
        height: auto;



    }

    .solution_shade {
        font-size: 40px;
    }

    .solution_product1,
    .solution_product2 {
        .flex {
            align-items: flex-start;
            gap: 40px;
            padding: 60px 0;
        }

        .solution_title {
            font-size: 40px;
        }

        ul {
            padding-left: 20px;

            li {

                font-size: 20px;
            }
        }
    }

    .solution_product1 {
        .flex {
            flex-direction: column;
        }
    }

    .solution_product2 {
        .flex {
            flex-direction: column-reverse;
        }
    }

    .product_box {
        .flex {
            flex-direction: column;
        }

        .pro_title {
            font-size: 30px;
            line-height: 40px;
        }

        .shade {
            font-size: 30px;
            line-height: 40px;
        }

        .pro_right {
            p {
                font-size: 20px;
            }
        }
    }

    .unlock_wrap {
        .clinician_box {

            gap: 40px;

            .title {
                width: 100%;
                font-size: 24px;
                line-height: 30px;
            }
        }

        .work_item_box {
            .work_item {
                flex-direction: column;
                gap: 20px;
                padding: 14px 16px;
                border-radius: 16px;
            }
        }

        .product_partner {
            gap: 20px;

            h2 {
                font-size: 24px;
                line-height: 30px;
            }

            p {
                font-size: 16px;
                line-height: 26px;
            }
        }

        .statistician {
            h2 {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
}

@media (max-width:390px) {
    .banner_box .banner_top .slogan {
        font-size: 32px;
    }

    .banner_box {
        .container {
            margin: 0 0 0 20px;
        }
    }
}

@media (width:390px) {
    .banner_box .banner_top .slogan {
        font-size: 34px;
    }
}

@media (min-width: 391px) and (max-width: 577px) {
    .banner_box .banner_top .slogan {
        font-size: 34px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .nav_product1 {
        padding: 60px 0;

        .flex {
            flex-flow: column-reverse;
        }
    }

    .product_title {
        font-size: 32px;
        line-height: 36px;

        .shade {
            font-size: 32px;
            line-height: 36px;
        }
    }

    .product01_img,
    .product02_img {
        margin-bottom: 26px;
    }

    .product_img01,
    .product_img02 {
        width: 100%;
    }

    .nav_product2 {
        padding: 60px 0;

        .flex {
            flex-flow: column;
        }
    }



    .solution_shade {
        font-size: 56px;
    }

    .solution_product1,
    .solution_product2 {
        .flex {
            align-items: flex-start;
            gap: 40px;
            padding: 60px 0;
        }

        .solution_title {
            font-size: 56px;
        }

        ul {
            padding-left: 20px;


        }
    }

    .solution_box {
        padding: 80px 0;
    }

    .solution_product1 {
        .flex {
            flex-direction: column;
        }
    }

    .solution_product2 {
        .flex {
            flex-direction: column-reverse;
        }
    }

    .product_box {
        .flex {
            flex-direction: column;
        }

        .pro_title {
            font-size: 30px;
            line-height: 40px;
        }

        .shade {
            font-size: 30px;
            line-height: 40px;
        }

        .pro_right {
            p {
                font-size: 20px;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1365px) {
    .partner_box {
        margin-top: 120px;
    }

    .banner_box {
        .banner_top {
            .slogan {
                font-size: 50px;
            }

            span {
                font-size: 28px;
            }

            .new_slogan {
                font-size: 28px;
            }
        }
    }

    .nav_product1 .flex,
    .nav_product2 .flex {
        gap: 44px;
    }


    .footer {
        padding: 14px 0;
    }


    .lb_ban {
        left: 0;
        filter: blur(76px);
        bottom: 22%;
    }

    .foot_wrap {
        flex-wrap: wrap;
        height: auto;
        margin-top: 20px;
    }



    .solution_box {
        padding: 80px 0;
    }

    .solution_product1,
    .solution_product2 {
        .flex {
            padding: 30px 0;
        }

        ul {
            li {
                font-size: 22px;
            }
        }

        .solution_title {
            font-size: 60px;
        }

    }

    .solution_shade {
        font-size: 60px;
    }

    .product_box {
        .pro_title {
            font-size: 30px;
            line-height: 40px;
        }

        .shade {
            font-size: 30px;
            line-height: 40px;
        }

        .pro_right {
            p {
                font-size: 20px;
            }
        }
    }

    .unlock_wrap {
        .product_partner {
            h2 {
                font-size: 28px;
            }

            p {
                font-size: 18px;
                line-height: 32px;
            }
        }

        .statistician {
            h2 {
                font-size: 28px;
                line-height: 46px;
            }
        }

    }



}
</style>
