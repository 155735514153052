<template>
    <div class="video-container">
        <video ref="videoPlayer" class="video-player" controls :poster="posterUrl"
            :style="{ height: videoHeight + 'px' }">
            <source :src="videoSrc" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';

// Props
const props = defineProps({
    videoSrc: {
        type: String,
        required: true,
        default: 'https://oss.hopeai.co/HopeAI_Aurora.mp4'
    },
    posterUrl: {
        type: String,
        required: true,
        default: '/img/groups.png'
    }
});

// Reactive references
const videoPlayer = ref(null);
const videoHeight = ref(0);
const posterImage = ref(null);

// Function to calculate and set video height
const calculateVideoHeight = () => {
    if (!videoPlayer.value) return;

    const videoWidth = videoPlayer.value.offsetWidth;

    if (posterImage.value) {
        // Use poster image aspect ratio if available
        const aspectRatio = posterImage.value.width / posterImage.value.height;
        videoHeight.value = videoWidth / aspectRatio;
    } else if (videoPlayer.value.videoWidth && videoPlayer.value.videoHeight) {
        // Use video's natural aspect ratio as fallback
        const aspectRatio = videoPlayer.value.videoWidth / videoPlayer.value.videoHeight;
        videoHeight.value = videoWidth / aspectRatio;
    } else {
        // Default to 16:9 aspect ratio if nothing else is available
        videoHeight.value = videoWidth * (9 / 16);
    }
};

// Handle window resize
const handleResize = () => {
    calculateVideoHeight();
};

// Watch for changes to the poster URL
watch(() => props.posterUrl, (newPosterUrl) => {
    if (newPosterUrl) {
        loadPosterImage(newPosterUrl);
    }
});

// Load poster image and get its dimensions
const loadPosterImage = (url) => {
    posterImage.value = new Image();
    posterImage.value.onload = () => {
        calculateVideoHeight();
    };
    posterImage.value.src = url;
};

onMounted(() => {
    // Load poster image
    if (props.posterUrl) {
        loadPosterImage(props.posterUrl);
    }

    // Add event listener for video metadata
    if (videoPlayer.value) {
        videoPlayer.value.addEventListener('loadedmetadata', calculateVideoHeight);
    }

    // Add window resize event listener
    window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
    // Clean up event listeners
    if (videoPlayer.value) {
        videoPlayer.value.removeEventListener('loadedmetadata', calculateVideoHeight);
    }
    window.removeEventListener('resize', handleResize);
});
</script>

<style scoped>
.video-container {
    width: 100%;
    /* max-width: 800px; */
    /* Adjust as needed */
    margin: 0 auto;
}

.video-player {
    width: 100%;
    display: block;
}
</style>