<template>
    <Teleport to="body">
        <transition name="fade">
            <div v-if="visible" class="fullscreen-modal" @click="handleBackdropClick">
                <div class="modal-content">
                    <div class="video-container">
                        <!-- 添加加载指示器 -->
                        <div v-if="isLoading" class="loading-indicator">
                            <div class="spinner"></div>
                            <p>Loading...</p>
                        </div>

                        <video ref="videoRef" :src="videoSrc" :autoplay="autoplay" :muted="muted" :controls="controls"
                            :loop="loop" @click.stop @loadedmetadata="handleVideoLoaded" @loadstart="isLoading = true"
                            @error="handleVideoError" class="video-player"
                            :class="{ 'video-hidden': isLoading }"></video>
                    </div>
                    <button @click="close" class="close-btn">
                        <span>&times;</span>
                    </button>
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script setup>
import { ref, watch, onMounted, nextTick } from 'vue';

const props = defineProps({
    visible: {
        type: Boolean,
        default: false
    },
    videoSrc: {
        type: String,
        required: true
    },
    autoplay: {
        type: Boolean,
        default: true
    },
    muted: {
        type: Boolean,
        default: false
    },
    controls: {
        type: Boolean,
        default: true
    },
    loop: {
        type: Boolean,
        default: false
    },
    // 默认视频比例，当视频未加载完成时使用
    aspectRatio: {
        type: String,
        default: '16:9'
    }
});

const emit = defineEmits(['update:visible', 'close', 'error']);

const videoRef = ref(null);
const isLoading = ref(true);
const hasError = ref(false);

// 计算默认宽高比
const getDefaultDimensions = () => {
    const [width, height] = props.aspectRatio.split(':').map(Number);
    // 设置一个合理的最大宽度
    const maxWidth = Math.min(window.innerWidth * 0.8, 1280);
    const calculatedHeight = (maxWidth * height) / width;

    return {
        width: `${maxWidth}px`,
        height: `${calculatedHeight}px`
    };
};

const handleVideoLoaded = () => {
    isLoading.value = false;
    hasError.value = false;
};

const handleVideoError = (error) => {
    isLoading.value = false;
    hasError.value = true;
    emit('error', error);
    console.error('视频加载失败:', error);
};

const close = () => {
    if (videoRef.value) {
        videoRef.value.pause();
    }
    emit('update:visible', false);
    emit('close');
};

// 处理背景点击事件
const handleBackdropClick = (event) => {
    // 检查点击是否发生在视频元素外部
    if (videoRef.value && !videoRef.value.contains(event.target)) {
        close();
    }
};

// 监听可见性变化，当可见时自动播放视频
watch(() => props.visible, async (newVal) => {
    if (newVal) {
        isLoading.value = true;
        hasError.value = false;

        // 确保DOM已更新
        await nextTick();

        if (videoRef.value) {
            try {
                await videoRef.value.play();
            } catch (error) {
                console.warn('自动播放失败，可能需要用户交互:', error);
                // 许多浏览器政策要求用户交互后才能播放带声音的视频
                videoRef.value.muted = true;
                try {
                    await videoRef.value.play();
                } catch (innerError) {
                    console.error('即使设置静音也无法自动播放:', innerError);
                }
            }
        }
    }
});

// 按ESC键关闭弹窗
onMounted(() => {
    const handleEsc = (e) => {
        if (e.key === 'Escape' && props.visible) {
            close();
        }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
        window.removeEventListener('keydown', handleEsc);
    };
});
</script>

<style scoped>
.fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(13, 18, 31, 0.85);
    backdrop-filter: blur(16px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* 应用最小尺寸，防止在加载时塌陷 */
    min-width: 320px;
    min-height: 180px;
}

.video-player {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    /* 确保视频始终填充容器 */
    height: 100%;
    /* aspect-ratio: 16 / 10; */
    object-fit: contain;
}

.video-hidden {
    opacity: 0;
}

/* 加载指示器样式 */
.loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 1;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 15px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;

    border: none;
    color: white;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}

.close-btn span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>